import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Button } from '../button'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import { currentTask } from '../../../entities/currentTask/model/currentTask'
import { tgState } from '../../../entities/tg/model/tgState'
import { useApi } from '../../hooks/api/useApi'
import { userLocationState } from '../../../entities/userPosition/model/locationState'
import { editState } from '../../../entities/editMode/model/editState'
import { AnimatePresence, motion } from 'framer-motion'
import { isWithin100Meters, smoothTransition } from '../../utils/mapUtils'
import { mapLocationState } from '../../../entities/mapLocation/model/mapLocationState'
import confetti from 'canvas-confetti'
import {userBalance} from "../../../entities/balance/model/userBalance";

export const NavbarUpper = () => {
	const [task, setTask] = useRecoilState(currentTask)
	const [edit, setEdit] = useRecoilState(editState)
	const [location, setLocation] = useRecoilState(mapLocationState)
	const setBalance = useSetRecoilState(userBalance);
	const tg = useRecoilValue(tgState)
	const { sendRequest } = useApi()
	const [resetLoad, setResetLoad] = useState(false)
	const [addLoad, setAddLoad] = useState(false)
	const [checkLoad, setCheckLoad] = useState(false)
	const [isCheckIn, setCheckIn] = useState(false)
	const [showRewards, setShowRewards] = useState({
		isShow: false,
		points: 0,
	})
	const [showPartner, setShowPartner] = useState({
		isShow: false,
		data: null,
	})

	const userLocation = useRecoilValue(userLocationState)

	const handleReset = async (e) => {
		tg['tg'].HapticFeedback.selectionChanged()
		if (e) {
			smoothTransition(
				location.center,
				location.zoom,
				[task.place.lon, task.place.lat],
				14.54,
				300,
				(updatedLocation) => {
					setLocation(updatedLocation)
				},
				async () => {
					try {
						setResetLoad(true)
						const response = await sendRequest('task/reset_task', {
							taskId: task.id
						})
						if (response.success) {
							setTask(null)
							tg['tg'].HapticFeedback.notificationOccurred(
								'success'
							)
						} else {
							tg.tg.showAlert(
								'Error has occurred. Please try again later.'
							)
							tg['tg'].HapticFeedback.notificationOccurred(
								'error'
							)
						}
					} catch (e) {
						console.log(e)
					} finally {
						setResetLoad(false)
					}
				}
			)
		}
	}
	const handleClickReset = () => {
		tg['tg'].HapticFeedback.selectionChanged()
		tg.tg.showConfirm('Are you sure you want to do this?', (e) =>
			handleReset(e)
		)
	}

	const handleWalkClick = () => {
		if (userLocation.isReady) {
			setEdit({ ...edit, isEdit: true })
			tg['tg'].HapticFeedback.selectionChanged()
		} else {
			tg.tg.showAlert('You need to enable navigation for the app.')
			tg['tg'].HapticFeedback.notificationOccurred('error')
		}
	}

	const handleCreateClick = async () => {
		try {
			setAddLoad(true)
			const response = await sendRequest('task/create_task', {
				location: { lat: edit.coords[1], lon: edit.coords[0] },
				userLocation: { lat: userLocation.lat, lon: userLocation.lon }
			})
			if (response.data) {
				setTask(response.data)
				setEdit({
					isEdit: false,
					isInvalid: true,
					coords: null
				})
				smoothTransition(
					location.center,
					location.zoom,
					[response.data.place.lon, response.data.place.lat],
					14.54,
					300,
					(updatedLocation) => {
						setLocation(updatedLocation)
					},
					() => {}
				)

				setTimeout(() => {
					tg['tg'].HapticFeedback.notificationOccurred('success')
				}, 600)
			} else {
				tg.tg.showAlert('Error has occurred. Please try again later.')
				tg['tg'].HapticFeedback.notificationOccurred('error')
			}
		} catch (e) {
			console.log(e)
		} finally {
			setAddLoad(false)
		}
	}

	const handleCheckClick = async () => {
		try {
			setCheckLoad(true);
			const response = await sendRequest('task/check_in', {
				taskId: task.id,
				location: { lat: userLocation.lat, lon: userLocation.lon }
			})
			if (response.success) {
				successClaim(response);
			} else {
				tg.tg.showAlert('Error has occurred. Please try again later.')
				tg['tg'].HapticFeedback.notificationOccurred('error')
			}
		} catch (e) {
			console.log(e)
		} finally {
			setCheckLoad(false)
		}

	}

	const successClaim = (data) => {
		setBalance(data.balance);
		setTask(null);
		setShowRewards({
			isShow: true,
			points: data.reward,
		});
		if (data.partnerTask) {
			setShowPartner({
				isShow: true,
				data: data.partnerTask,
			})
		}


		setTimeout(()=>{
			setShowRewards( {
				isShow: false,
				points: 0,
			});
		},3000);


		const canvas = document.getElementById('confetti-canvas')
		confetti.create(canvas, { resize: true })({
			particleCount: 150,
			spread: 100,
			origin: { y: 0.8 }
		})

		tg['tg'].HapticFeedback.impactOccurred('soft')
	}

	useEffect(() => {
		const canvas = document.createElement('canvas')
		canvas.id = 'confetti-canvas'
		canvas.style.position = 'fixed'
		canvas.style.top = '0'
		canvas.style.left = '0'
		canvas.style.width = '100%'
		canvas.style.height = '100vh'
		canvas.style.pointerEvents = 'none' // Чтобы клики проходили сквозь канвас
		canvas.style.zIndex = '999'
		document.body.appendChild(canvas)

		confetti.create(canvas, {
			resize: true // Изменение размера канваса при изменении размеров окна
		})

		return () => {
			document.body.removeChild(canvas)
		}
	}, [])

	useEffect(() => {
		if (userLocation && task) {
			const canClaim = isWithin100Meters(
				userLocation.lat,
				userLocation.lon,
				task.place.lat,
				task.place.lon
			)
			setCheckIn(canClaim)
			// setCheckIn(true);
		}
	}, [userLocation, task])

	return (
		<Wrapper>
			{edit.isEdit && edit.isInvalid && (
				<Modal
					initial="hidden"
					animate="visible"
					exit="hidden"
					variants={modalVariants}
					transition={{ duration: 0.2, ease: 'easeInOut' }}
				>
					<p>Set up map marker outside of the restricted area</p>
				</Modal>
			)}
			{showRewards.isShow && (
				<Modal
					initial="hidden"
					animate="visible"
					exit="hidden"
					variants={modalVariants}
					transition={{ duration: 0.2, ease: 'easeInOut' }}
				>
					<img src="/assets/icon/successIcon.svg" alt="successIcon"/>
					<p>{showRewards.points} point for checking</p>
				</Modal>
			)}

			{!task &&
				(edit.isEdit ? (
					<Button
						width={'100%'}
						isDisabled={edit.isInvalid}
						disabledBackgroundColor={'#3E3E3E'}
						disabledColor={'#B6B6B6'}
						isLoading={addLoad}
						onClick={() => handleCreateClick()}
					>
						Set up marker
					</Button>
				) : (
					<Button width={'100%'} onClick={() => handleWalkClick()}>
						Walk here
					</Button>
				))}
			{task && (
				<>
					<Button
						width={'30%'}
						color={'#E53935'}
						backgroundColor={'rgba(229,57,53, 0.2)'}
						onClick={() => handleClickReset()}
						isLoading={resetLoad}
					>
						Reset
					</Button>
					<Button
						width={'70%'}
						isDisabled={!isCheckIn}
						isLoading={checkLoad}
						backgroundColor={'#32E55E'}
						disabledBackgroundColor={'#3E3E3E'}
						disabledColor={'#B6B6B6'}
						onClick={() => handleCheckClick()}
					>
						Check in
					</Button>
				</>
			)}
			<AnimatePresence>
			{showPartner.isShow && (
				<PartnerWrapperModal>
					<PartnerModal
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={modalVariants}
						transition={{ duration: 0.2, ease: 'easeInOut' }}
					>
						<div style={{cursor: 'pointer', width: '28px', height: '28px', position: 'absolute', top: '20px', right: '20px'}} onClick={()=>{
							setShowPartner({ data: null, isShow: false});
						}}>
							<img style={{width: '100%', height: '100%', boxShadow: 'none'}} src="/assets/icon/closeModal.svg" alt="close"/>
						</div>
						<img style={{marginBottom: '24px'}} src={showPartner.data.icon} alt="partnerIcon"/>
						<p style={{fontWeight: '500', fontSize: '20px', marginBottom: '4px'}}>{showPartner.data.title}</p>
						<p style={{fontWeight: '400', fontSize: '17px', marginBottom: '52px'}}>{showPartner.data.description}</p>
						<Button width={'100%'} onClick={() => {
							window.location.href = showPartner.data.link;
						}}>
							Get bonus
						</Button>
					</PartnerModal>
				</PartnerWrapperModal>
			)}
			</AnimatePresence>
		</Wrapper>
	)
}
const modalVariants = {
	hidden: { opacity: 0, y: 100 },
	visible: { opacity: 1, y: 0 }
}
const PartnerWrapperModal = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,0.7);
	left: 0;
	top: 0;
`
const PartnerModal = styled(motion.div)`
	position: fixed;
	width: 100%;
	bottom: 0;
	background: #212121;
	border-top-right-radius: 24px;
	border-top-left-radius: 24px;
	text-align: center;
	padding: 36px 20px 50px;
	
	img {
		box-shadow: 0 0 40px 8px rgba(255,255,255,0.15);
		width: 100px;
		height: 100px;
		border-radius: 100%;
	}
	p {
		color: white;
	}
`
const Modal = styled(motion.div)`
	display: flex;
	gap: 20px;
	font-size: 15px;
	font-weight: 400;
	color: #fff;
	flex-direction: row;
	padding: 16px 20px;
	background: rgba(27, 27, 27, 0.75);
	width: calc(100% - 32px);
	position: absolute;
	bottom: calc(100% + 28px);
	left: 16px;
	border-radius: 16px;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	box-sizing: border-box;
	align-items: center;
	img {
		width: 20px;
	}
`

const Wrapper = styled.div`
	box-shadow: 0px -4px 4px 0px rgb(0 0 0 / 15%);
	background: #212121;
	padding: 20px 16px;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	display: flex;
	flex-direction: row;
	gap: 12px;
`