import React, {Fragment, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {motion} from "framer-motion";
import { Divider, MainText, PageIcon, SecondText, WolfBG, WrapperList} from "../earn/ui";
import bgWolf from "../../shared/assets/bg/bgWolf.png";
import {useApi} from "../../shared/hooks/api/useApi";
import {useRecoilValue} from "recoil";
import {tgState} from "../../entities/tg/model/tgState";
import {useNavigate} from "react-router-dom";

export const JoinClub = () => {
    const [gifLoaded, setGifLoaded] = useState(false)
    const [clubs, setClubs] = useState([]);

    const gifRef = useRef(null)
    const {sendRequest} = useApi();
    const tg = useRecoilValue(tgState);
    const navigate = useNavigate();

    useEffect(() => {
        const img = new Image()
        img.src = '/assets/icon/diamond.gif'
        img.onload = () => {
            setGifLoaded(true)
            if (gifRef.current) {
                gifRef.current.src = ''
                gifRef.current.src = '/assets/icon/diamond.gif'
            }
        }
        if (gifRef.current) {
            gifRef.current.src = ''
            gifRef.current.src = '/assets/icon/diamond.gif'
        }
    }, [])
    useEffect(()=>{
        const fetch = async () => {
            try {
                const response = await sendRequest('club/get_clubs');
                setClubs(response.data);
            } catch (e) {
                console.log(e);
            }
        }
        fetch();
    },[]);


    const handleJoinClick = async () => {
        tg['tg'].HapticFeedback.impactOccurred('soft')
        const response = await sendRequest('bot/cmd/join')
        setTimeout(() => tg['tg'].close(), 100)
    }

    return (
        <ModalContainer>
            <Wrapper>
                <WolfBG src={bgWolf} alt="bgWolf" />
                <PageIcon
                    src={gifLoaded ? '/assets/icon/diamond.gif' : ''}
                    alt={'anim'}
                    ref={gifRef}
                />
                <MainText>Join clubs</MainText>
                <SecondText style={{ textAlign: 'center', marginTop: '0' }}>
                    These clubs are waiting for people, <br/>
                    join any
                </SecondText>
                <Button onClick={handleJoinClick}>
                    Join another club
                </Button>
                <WrapperList>
                    <Body>
                        {clubs.map((item, index) => (
                            <Fragment key={index}>
                                <Item onClick={()=>{
                                    tg['tg'].HapticFeedback.selectionChanged();
                                    navigate(`/club-info/${item.id}`);
                                }}>
                                   <FlexRow  style={{alignItems: 'flex-start'}}>
                                       <BadgeWrapper>
                                           <Badge src="/assets/icon/badgeBronze.svg" alt="badge"/>
                                           <BadgeInner src="/assets/icon/wolf.png" alt="badge inner"/>
                                       </BadgeWrapper>
                                       <FlexClmn>
                                           <p style={{fontSize: '17px', fontWeight: '400', lineHeight: '22px', color: '#FFF'}}>{item.name}</p>
                                           <p style={{fontSize: '15px', fontWeight: '400', lineHeight: '20px', color: '#C5C5C5', marginTop: '2px'}}>{item.totalScore}</p>
                                           <Divider style={{marginLeft: '0', marginTop:'16px' }}  className={index === clubs.length-1 && 'last' }/>
                                       </FlexClmn>
                                       <Arrow src="/assets/icon/arrow.svg" alt="arrow"/>
                                   </FlexRow>
                                </Item>

                            </Fragment>
                        ))}
                    </Body>
                </WrapperList>
            </Wrapper>
        </ModalContainer>
    );
};

const Button = styled.div`
  background: #2990FF;
  font-weight: 500;
  color: #fff;
  font-size: 17px;
  text-align: center;
  padding: 14px;
  width: 100%;
  border-radius: 10px;
  margin: 28px 0;
`
const Arrow = styled.img`
  position: absolute;
  right: 16px;
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
`
const Body = styled.div`
	width: 100%;
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(32px);
	-webkit-backdrop-filter: blur(32px);
	display: flex;
	flex-direction: column;
`
const FlexClmn = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
const FlexRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`

const BadgeWrapper = styled.div`
	width: 48px;
	height: 48px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
const Badge = styled.img`
	width: 100%;
	height: 100%;
`
const BadgeInner = styled.img`
	position: absolute;
	width: 31px;
	height: 31px;
`

const Item = styled.div`
    position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px 0 12px;
`

const ModalContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 998;
	background: rgb(67, 74, 255);
	background: radial-gradient(
		circle at top,
		rgba(67, 74, 255, 1) 0%,
		rgba(31, 36, 155, 1) 33%,
		rgba(0, 0, 0, 1) 70%
	);
	overscroll-behavior: none;
`
const Wrapper = styled(motion.div)`
	width: 100%;
	height: calc(100% + 1px);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	padding: 40% 20px 40px;
	position: relative;
	background: transparent;
	z-index: 999;
`