import styled, { keyframes } from 'styled-components'
import logo from '../../../shared/assets/icons/logo.svg'
import React from 'react'

const PageLoader = () => {
	return (
		<Wrapper>
			<StyledLogo src={logo} alt="logo" />
		</Wrapper>
	)
}

export default PageLoader

const Wrapper = styled.div`
	background: #212121;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 999;
`

const pulseFade = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
  }
`

const StyledLogo = styled.img`
	width: 20%;
	max-width: 250px;
	animation: ${pulseFade} 2s infinite;
`