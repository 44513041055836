import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { tasksState } from '../../entities/tasks/model/tasksState'
import { friendsState } from '../../entities/friends/model/friendsState'
import { useApi } from '../../shared/hooks/api/useApi'
import { tgState } from '../../entities/tg/model/tgState'
import { currentTask } from '../../entities/currentTask/model/currentTask'
import { userBalance } from '../../entities/balance/model/userBalance'
import bgWolf from '../../shared/assets/bg/bgWolf.png'
import coinIcon from '../../shared/assets/icons/coins.svg'
import earnPageIcon from '../../shared/assets/icons/earpPageIcon.png'
import { logWithColor } from '../../shared/utils/debugUtils'
import { strikeState } from "../../entities/strikeMode/model/strikeState";
import { userClubState } from "../../entities/userClub/model/userClubState";
import { leaderState } from "../../entities/leaderboards/model/leaderState";

const imagesPreload = [
	bgWolf,
	coinIcon,
	earnPageIcon,
	'/assets/icon/money.gif',
	'/assets/icon/friends.gif',
	'/assets/icon/diamond.gif',
	'/assets/icon/badgeBronze.svg',
	'/assets/icon/wolf.png',
	'/assets/icon/arrow.svg',
]

export const useInit = () => {
	const [isInitialized, setIsInitialized] = useState(false)

	const setTasks = useSetRecoilState(tasksState)
	const setFriends = useSetRecoilState(friendsState)
	const setTelegram = useSetRecoilState(tgState)
	const setTask = useSetRecoilState(currentTask)
	const setBalance = useSetRecoilState(userBalance)
	const setStrikeMode = useSetRecoilState(strikeState)
	const setUserClubState = useSetRecoilState(userClubState);
	const setLeaderBoard = useSetRecoilState(leaderState);
	const { sendRequest } = useApi()

	useEffect(() => {
		const fetchData = async () => {
			try {
				// INIT TG
				const tg = window.Telegram?.WebApp
				tg.expand();
				tg.ready();
				logWithColor(`[TG] version: ${tg.version}`, '#007BFF')
				logWithColor(`[TG] isVersionAtLeast: ${tg.isVersionAtLeast(tg.version)}`, '#007BFF')

				tg.disableVerticalSwipes();
				tg.setHeaderColor('#212121')
				tg.isClosingConfirmationEnabled = true
				setTelegram({
					initData: tg.initDataUnsafe,
					isReady: true,
					minHeight: tg.viewportStableHeight,
					tg: tg,
					language: tg.initDataUnsafe.user.language_code || 'en',
					hash: tg.initDataUnsafe.hash || null
				})

				const [responseUserInfo, responseTasks, responseFriends, responseTop3] =
					await Promise.all([
						sendRequest('user/get_user_info'),
						sendRequest('task/get_earn_tasks'),
						sendRequest('user/friends'),
						sendRequest('user/top3')
					])

				// Process user info
				setTask(responseUserInfo.data.task)
				setBalance(responseUserInfo.data.balance)
				setStrikeMode(responseUserInfo.data.user.strikeMode)
				setLeaderBoard({
					top3: responseTop3.data.topUsers,
					userPosition: responseTop3.data.userPosition
				})
				setUserClubState(responseTop3.data.userClub);

				// Process tasks
				const { availableTasks, completedTasks } = responseTasks.data
				const availableTasksFormatted = availableTasks.map((task) => ({
					id: task.id,
					title: task.icon, // Измените это на task.title, если есть подходящее поле
					description: task.description,
					rewards: task.reward.toString(),
					link: task.link,
					isComplete: false
				}))
				const completedTasksFormatted = completedTasks.map((task) => ({
					id: task.taskId,
					title: task.task.icon, // Измените это на task.task.title, если есть подходящее поле
					description: task.task.description,
					rewards: task.task.reward.toString(),
					link: task.task.link,
					isComplete: true
				}))
				const mergedTasks = [
					...availableTasksFormatted,
					...completedTasksFormatted
				]
				const sortedTasks = mergedTasks.sort(
					(a, b) => Number(a.isComplete) - Number(b.isComplete)
				)

				setTasks(sortedTasks)
				setFriends(responseFriends.data.list)

				// IMAGE PRELOAD
				imagesPreload.forEach((image) => {
					const newImage = new Image()
					newImage.src = image
					window[image] = newImage
				})

				setIsInitialized(true)
				logWithColor(`[APP] - INIT`, '#47d538')
			} catch (error) {
				logWithColor(`[APP] - INIT\n${error}`, 'rgba(217,69,73,0.87)')
			}
		}

		fetchData()
	}, [sendRequest, setTasks, setFriends, setTelegram, setTask, setBalance])

	return isInitialized
}