import styled from 'styled-components'
import { motion } from 'framer-motion'
import {useRecoilState, useRecoilValue} from "recoil";
import {strikeState} from "../../entities/strikeMode/model/strikeState";
import {userBalance} from "../../entities/balance/model/userBalance";
import {useEffect} from "react";
import {userClubState} from "../../entities/userClub/model/userClubState";
import {leaderState} from "../../entities/leaderboards/model/leaderState";
import {useApi} from "../../shared/hooks/api/useApi";
import {useNavigate} from "react-router-dom";
import {tgState} from "../../entities/tg/model/tgState";

export const AccountPage = () => {
	const strikeMode = useRecoilValue(strikeState);
 	const balance = useRecoilValue(userBalance);
	const club = useRecoilValue(userClubState);
	const [leader, setLeaderBoard] = useRecoilState(leaderState);
	const { sendRequest } = useApi();
	const navigate = useNavigate();
	const tg = useRecoilValue(tgState)

	useEffect(()=>{
		const fetch = async () => {
			try {
				const response = await sendRequest('user/top3');
				setLeaderBoard({
					top3: response.data.topUsers,
					userPosition: response.data.userPosition
				})
			} catch (e) {
				console.log(e);
			}
		}
		fetch();
	},[]);


	return (
		<ModalContainer>
			<Wrapper>
				<FlexRow>
					<Box style={{width: '60%'}}>
						<FlexClmn style={{gap: '27px', height: '100%', justifyContent: 'space-between'}}>
							<div>Total score</div>
							<FlexRow style={{gap:'8px'}}>
								<p style={{fontSize: '40px', fontWeight: '500'}}>{balance}</p>
								<BadgeWrapperSmall>
									<Badge src="/assets/icon/badgeBronze.svg" alt="badge"/>
									<BadgeWhite></BadgeWhite>
									<BadgeText className={'bronze'}>{strikeMode?.consecutiveDays || '0'}</BadgeText>
								</BadgeWrapperSmall>

							</FlexRow>
						</FlexClmn>
					</Box>
					<Box style={{width: '40%'}} onClick={()=>{
						tg['tg'].HapticFeedback.selectionChanged();
						if (club) {
							navigate(`/club-info/${club.id}`);
						} else {
							navigate('/clubs');
						}

					}}>
						<FlexClmn style={{gap: '12px', alignItems: 'center'}}>
							<BadgeWrapper>
								<Badge src="/assets/icon/badgeBronze.svg" alt="badge"/>
								<BadgeInner src="/assets/icon/wolf.png" alt="badge inner"/>
							</BadgeWrapper>
							{club ? (
								<div style={{textAlign: 'center'}}>
									<p style={{fontSize: '15px', fontWeight: '400', lineHeight: '20px'}}>{club.name}</p>
									<p style={{fontSize: '17px', fontWeight: '500', lineHeight: '22px'}}>{club.totalScore}</p>
								</div>
							):(
								<div style={{color: '#2990FF'}} >
									Join club
								</div>
							)}
						</FlexClmn>
					</Box>
				</FlexRow>
				<FlexRow>
					<Box>
						<FlexClmn style={{gap: '24px'}}>
							<FlexRow style={{justifyContent: 'space-between'}}>
								<div  style={{color: '#FFF', fontWeight: '500', fontSize: '17'}}>
									Leaderboard
								</div>
								<div style={{color: '#2990FF', fontWeight: '500', fontSize: '17'}} onClick={()=>{
									tg['tg'].HapticFeedback.selectionChanged();
									navigate('/leaderboard');
								}}>
									Top 100
								</div>
							</FlexRow>
							<FlexRow style={{justifyContent: 'space-around'}}>
								<FlexClmn style={{alignItems: 'center'}}>
									<TopBadge className={'second'}>
										<p>{leader.top3[1].firstName.split('')[0].toUpperCase()}</p>
										<span className={'second'}>2</span>
									</TopBadge>
									<p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.top3[1].firstName}</p>
									<p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.top3[1].totalScore}</p>
								</FlexClmn>
								<FlexClmn style={{alignItems: 'center'}}>
									<TopBadge className={'first'}>
										<p>{leader.top3[0].firstName.split('')[0].toUpperCase()}</p>
										<span className={'first'}>1</span>
									</TopBadge>
									<p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.top3[0].firstName}</p>
									<p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.top3[0].totalScore}</p>
								</FlexClmn>
								<FlexClmn style={{alignItems: 'center'}}>
									<TopBadge className={'third'}>
										<p>{leader.top3[2].firstName.split('')[0].toUpperCase()}</p>
										<span className={'third'}>3</span>
									</TopBadge>
									<p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.top3[2].firstName}</p>
									<p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.top3[2].totalScore}</p>
								</FlexClmn>
							</FlexRow>
							{(Number(leader.userPosition.position) > 3) &&
								<FlexRow style={{gap: '12px'}}>
									<p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.userPosition.position}</p>
									<TopBadge className={'default'}>
										<p>{leader.userPosition.firstName.split('')[0].toUpperCase()}</p>
									</TopBadge>
									<FlexClmn style={{width: 'fit-content'}}>
										<p style={{fontSize: '17px', color: '#FFF', fontWeight: '400', lineHeight: '22px'}}>{leader.userPosition.firstName}</p>
										<p style={{fontSize: '15px', color: '#C9CFD5', fontWeight: '400',lineHeight: '22px'}}>{leader.userPosition.totalScore}</p>
									</FlexClmn>
								</FlexRow>
							}
						</FlexClmn>
					</Box>
				</FlexRow>
				<FlexRow>
					<Box>
						<FlexClmn>
							<p>Bonuses</p>
							<p style={{color: 'gray', fontSize:'25px', textAlign: 'center', margin: '80px 0 80px'}}>Coming soon</p>
						</FlexClmn>
					</Box>
				</FlexRow>
				<FlexRow>
					<div>Channel</div>
					<div>Chat</div>
				</FlexRow>
			</Wrapper>
		</ModalContainer>
	)
}

const TopBadge = styled.div`
	position: relative;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	p {
		font-size: 32px;
		font-weight: 500;
		position: absolute;
		color: #000;
	}
	&.default {
		width: 48px;
		height: 48px;
		border: 4px solid #FFF;
	}
	&.first {
		margin-bottom: 12px;
		border: 4px solid #FEC44B;
		width: 68px;
		height: 68px;
		color: #AE8125; 
	}
	&.second {
		margin-bottom: 16px;
		border: 4px solid #ACACAC;
		color: #6A6A6A;
	}
	&.third {
		margin-bottom: 16px;
		border: 4px solid #E18327;
		color: #854B12;
	}
	span {
		position: absolute;
		top: -4px;
		left: -4px;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		color: #FFF;
		font-size: 15px;
		font-weight: 600;
		text-align: center;
		&.first {
			background:#FEC44B;
		}
		&.second {
			background:#ACACAC;
		}
		&.third {
			background:#E18327;
		}
	}
`
const BadgeWhite = styled.div`
	position: absolute;
	border-radius: 100%;
	background: #fff;
	width: 65%;
	height: 65%;
`
const BadgeText = styled.p`
	position: absolute;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	&.bronze {
		background: linear-gradient(90deg, #AAA87D 0%, #B57B0E 31%, #A08042 76%, #BCAC66 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
`
const BadgeWrapper = styled.div`
	width: 56px;
	height: 56px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
const BadgeWrapperSmall = styled(BadgeWrapper)`
	width: 36px;
	height: 36px;
`
const Badge = styled.img`
	width: 100%;
	height: 100%;
`
const BadgeInner = styled.img`
	position: absolute;
	width: 38px;
	height: 38px;
`
const Box = styled.div`
	background: #1C1C1E;
	border-radius: 20px;
	padding: 20px 16px;
	color: #fff;
	font-size: 17px;
	height: 100%;
	width: 100%;
`
const FlexClmn = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`
const FlexRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`
const ModalContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 998;
	background: #000000;
	overscroll-behavior: none;
`
const Wrapper = styled(motion.div)`
	width: 100%;
	height: calc(100% + 1px);
	display: flex;
	gap: 16px;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	padding: 16px;
	position: relative;
	background: transparent;
	z-index: 999;
`