import React, { useRef, useEffect, useState, useMemo, Fragment } from 'react'
import styled from 'styled-components'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { tasksState } from '../../entities/tasks/model/tasksState'
import { tgState } from '../../entities/tg/model/tgState'
import { useApi } from '../../shared/hooks/api/useApi'
import { userBalance } from '../../entities/balance/model/userBalance'
import checkedIcon from '../../shared/assets/icons/checkedIcon.svg'
import bgWolf from '../../shared/assets/bg/bgWolf.png'
import { motion } from 'framer-motion'

export const EarnPage = () => {

	const { sendRequest } = useApi()
	const [tasks, setTasks] = useRecoilState(tasksState)
	const [loadingTasks, setLoadingTasks] = useState([])
	const [gifLoaded, setGifLoaded] = useState(false) // Состояние для отслеживания загрузки GIF
	const gifRef = useRef(null)

	const tg = useRecoilValue(tgState)
	const setBalance = useSetRecoilState(userBalance)

	useEffect(() => {
		const img = new Image()
		img.src = '/assets/icon/money.gif'
		img.onload = () => {
			setGifLoaded(true)
			if (gifRef.current) {
				gifRef.current.src = ''
				gifRef.current.src = '/assets/icon/money.gif' // обновляем src для перезапуска gif
			}
		}
		if (gifRef.current) {
			gifRef.current.src = ''
			gifRef.current.src = '/assets/icon/money.gif' // обновляем src для перезапуска gif
		}
	}, [])

	const handleTaskClick = (taskId, link) => {
		if (loadingTasks.includes(taskId)) return

		setLoadingTasks((prev) => [...prev, taskId])

		window.location.href = link

		setTimeout(async () => {
			try {
				// Выполняем запрос на проверку статуса задачи
				const response = await sendRequest('task/complete_earn_task', {
					taskId: taskId
				})
				if (response.success) {
					setTasks((prevTasks) => {
						const updatedTasks = prevTasks.map((task) =>
							task.id === taskId
								? { ...task, isComplete: true }
								: task
						)
						const sortedTasks = [...updatedTasks].sort(
							(a, b) =>
								Number(a.isComplete) - Number(b.isComplete)
						)
						return sortedTasks
					})
					setBalance(response.balance)
					tg['tg'].HapticFeedback.notificationOccurred('success')
				} else {
					console.error(
						'Task not completed successfully:',
						response.data
					)
				}
			} catch (error) {
				console.error('Error completing task:', error)
			}

			setLoadingTasks((prev) => prev.filter((id) => id !== taskId))
		}, 10000)
	}

	const memoizedTasks = useMemo(() => tasks, [tasks])

	return (
		<ModalContainer>
			<Wrapper>
				<WolfBG src={bgWolf} alt="bgWolf" />
				<PageIcon
					src={gifLoaded ? '/assets/icon/money.gif' : ''}
					alt={'anim'}
					ref={gifRef}
				/>
				<MainText>Earn</MainText>
				<SecondText>Complete tasks and get extra points</SecondText>
				<WrapperList>
					<Body>
						{memoizedTasks.map((item, index) => (
							<Fragment key={item.id}>
								<Item>
									<Flex>
										<BigText>{item.title}</BigText>
										<SmallText>
											{item.description}, +{item.rewards}
										</SmallText>
									</Flex>
									{item.isComplete ? (
										<IconContainer>
											<CheckedIcon
												src={checkedIcon}
												alt="checked"
											/>
										</IconContainer>
									) : (
										<Button
											onClick={() =>
												handleTaskClick(
													item.id,
													item.link
												)
											}
										>
											{loadingTasks.includes(item.id) ? (
												<Preloader />
											) : (
												'Open'
											)}
										</Button>
									)}
								</Item>
								{index !== tasks.length - 1 && <Divider />}
							</Fragment>
						))}
					</Body>
				</WrapperList>
			</Wrapper>
		</ModalContainer>
	)
}

const ModalContainer = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 998;
	background: rgb(196, 176, 253);
	background: radial-gradient(
		circle at top,
		rgba(147, 137, 175, 1) 0%,
		rgba(98, 66, 190, 1) 33%,
		rgba(0, 0, 0, 1) 70%
	);
	overscroll-behavior: none;
`

const Wrapper = styled(motion.div)`
	width: 100%;
	height: calc(100% + 1px);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	padding: 40% 20px 20px;
	position: relative;
	background: transparent;
	z-index: 999;
`

export const WolfBG = styled.img`
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
`

export const PageIcon = styled.img`
	position: absolute;
	top: 50px;
	width: 27%;
	&.center {
		margin-right: -20px;
	}
`

export const IconContainer = styled.div`
	min-width: 56.7px;
	display: flex;
	justify-content: center;
`

export const CheckedIcon = styled.img`
	width: 24px;
	height: 24px;
`

export const MainText = styled.p`
	color: white;
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 0.07px;
	line-height: 28px;
	margin-bottom: 4px;
`

export const SecondText = styled.p`
	color: white;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 12px;
`

export const WrapperList = styled.div`
	width: 100%;
`

export const Flex = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`

export const Button = styled.div`
	cursor: pointer;
	border-radius: 16px;
	color: white;
	background: rgba(255, 255, 255, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 6px 10px;
	font-size: 15px;
	font-weight: 500;
	min-width: 56.7px;
`

export const SmallText = styled.div`
	font-weight: 400;
	font-size: 15px;
	color: #c5c5c5;
`

export const BigText = styled.div`
	font-weight: 400;
	font-size: 17px;
	color: #fff;
`

const Item = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	cursor: pointer;
`

 const Body = styled.div`
	width: 100%;
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(32px);
	-webkit-backdrop-filter: blur(32px);
	display: flex;
	flex-direction: column;
`

export const Divider = styled.div`
	
	height: 0.5px; /* Толщина 0.5px */
	background: #94949469;
	border-radius: 0.25px;
	margin-left: 16px;
	&.last {
		background: transparent;
	}
`

export const Preloader = styled.div`
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-top: 2px solid white;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	animation: spin 0.6s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`